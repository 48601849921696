<template>
  <base-section
    id="consulting-engineering"
  >
    <base-section-heading
      icon="mdi-account-wrench"
      title="컨설팅·엔지니어링"
      text="다양한 업종의 컨설팅 노하우와 풍부한 공장 및 창고 자동화 설계 및 엔지니어링 경험을 바탕으로 최고의 제안을 드리겠습니다."
    />
    <v-container>
      <v-responsive
        class="d-flex align-center mx-auto"
        height="100%"
        width="100%"
      >
        <h1
          class="text-center"
          style="color:#214987;"
        >
          비즈니스 분야
        </h1>
        <br>
        <br>
        <v-row>
          <v-col
            cols="3"
            class="text-center"
          >
            <template
              v-for="(card, i) in cards"
            >
              <base-avatar-card
                :key="i"
                align="center"
                v-bind="card"
                @click.native="fnChangeTab(card)"
              />
              <v-divider
                v-if="i + 1 !== cards.length"
                :key="`divider-${i}`"
                class="my-8"
              />
            </template>
          </v-col>

          <v-col
            cols="1"
            class="text-center px-0 mx-0"
          >
            <v-responsive
              height="calc(100% - 16px)"
              class="mx-0 px-0"
            >
              <v-divider
                vertical
                dark
                class="mx-0 px-0"
              />
            </v-responsive>
          </v-col>

          <v-col
            cols="8"
          >
            <base-subheading
              size="text-h5"
              :title="cards[currentTab].title"
              space="0"
              mobile-size="text-h6"
              class="text-uppercase"
            />

            <base-title
              class="primary--text"
              :title="cards[currentTab].location"
              tag="div"
            />
            <base-body>
              {{ cards[currentTab].description }}
            </base-body>
            <v-row>
              <v-col
                v-for="detail in cards[currentTab].details"
                :key="detail"
                cols="12"
                md="6"
              >
                <base-list-item
                  :text="detail"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-responsive>
      <br>
      <br>
      <br>
      <br>
      <h1
        class="text-center"
        style="color:#214987;"
      >
        Implementation Procedure
      </h1>
      <br>
      <br>
      <v-row>
        <v-col
          v-for="process in processes"
          :key="process.title"
          cols="12"
          md="3"
        >
          <base-info-card-pacific
            v-bind="process"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionConsultingEngineering',

    data: () => ({
      currentTab: 0,
      cards: [
        {
          no: 0,
          title: '베이스 및 운영 최적화 컨설팅',
          description: '경영방침에 따른 중장기 전략 수립, 시설 합리화, 중장기 마스터플랜 수립.',
          chosen: true,
          details: [
            '입지분석 및 개발계획 수립/센터 입지 선정',
            'BPR (Business Process Re-engineering)',
            '조직합리화 컨설팅',
          ],
        },
        {
          no: 1,
          title: '자동화 컨설팅',
          description: 'RFP, 설계조건 등이 수립되지 않은 초기상태에서 데이터 분석, 개념배치, 설비사양, 운영계획, 예상투자금액 등을 수행하며, 신규센터 기획, 생산, 조달, 매장, 판매시스템 컨설팅 등을 제공함',
          details: [
            '데이터 분석(볼륨 분석, ABC 분석)',
            '현행제도 개선을 위한 배치 및 타당성 분석',
            '비용 분석 및 절감 계획',
            '제안시설 선정',
            'IT 솔루션 패키지 구축 컨설팅',
          ],
          chosen: false,
        },
        {
          no: 2,
          title: '엔지니어링',
          description: '고객의 기본 설계 조건을 활용하여 데이터 분석을 최소화하고 상세 레이아웃, 설비 사양, 시뮬레이션, 제어 및 정보 시스템 엔지니어링, 상세 견적 등을 수행합니다.',
          details: [
            '제안된 시스템 상세 레이아웃',
            '전반적인 시스템 볼륨 시뮬레이션',
            'IT 솔루션 소프트웨어 엔지니어링',
            '투자규모 결정',
          ],
          chosen: false,
        },
      ],
      processes: [
        {
          title: '상담요청',
          callout: '01',
          icon: 'mdi-account-search',
        },
        {
          title: '컨설팅',
          callout: '02',
          icon: 'mdi-account-voice',
        },
        {
          title: '현장점검',
          callout: '03',
          icon: 'mdi-store-search',
        },
        {
          title: '컨설팅 방안 제안',
          callout: '04',
          icon: 'mdi-presentation',
        },
        {
          title: '제안된 계획에 대한 컨설팅',
          callout: '05',
          icon: 'mdi-clipboard-edit',
        },
        {
          title: '계약',
          callout: '06',
          icon: 'mdi-text-box-edit',
        },
        {
          title: '상담',
          callout: '07',
          icon: 'mdi-human-male-board-poll',
        },
        {
          title: '중간보고서',
          callout: '08',
          icon: 'mdi-chart-line',
        },
        {
          title: '최종 완료',
          callout: '09',
          icon: 'mdi-check-all',
        },
        {
          title: '보증 및 사후 서비스',
          callout: '10',
          icon: 'mdi-phone-plus',
        },
      ],
    }),
    methods: {
      fnChangeTab (card) {
        this.currentTab = card.no
        this.cards[0].chosen = false
        this.cards[1].chosen = false
        this.cards[2].chosen = false
        card.chosen = true
      },
    },
  }
</script>
